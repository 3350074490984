<style scoped lang="scss">
    .BODY {
        padding: 0 0 2.04rem;
        background: #f5f5f5;
        flex: 1;
        @include background_color("bg");
    }
    .head{
        width: 100%;
        height: 1.16rem;
        line-height: 1.16rem;
        padding: 0 .48rem;
        font-size: .48rem;
        color: #181818;
        font-weight: bold;
        background: #FFFFFF;
        margin-bottom: .3rem;
        @include font_color("title_color");
        @include background_color("card_bg");
    }
    .skuList{
        width: 92%;
        margin: 0 auto;
        background: #FFFFFF;
        border-radius: .12rem;
        padding: .3rem;
        padding-bottom: 0;
        @include background_color("card_bg");

        .item{
            display: flex;
            margin-top: .4rem;
            &:first-child{
                margin-top: 0;
            }

            .img{
                width: 1.8rem;
                height: 1.8rem;
                border-radius: .16rem;
                margin-right: .2rem;
                position: relative;
                overflow: hidden;

                .image{
                    width: 100%;
                    height: 100%;
                }
                .region{
                    position: absolute;
                    z-index: 2;
                    width: .68rem;
                    height: .68rem;
                    top: 0;
                    left: 0;
                }
            }
            .info{
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: space-between;

                .name{
                    width: 100%;
                    height: .9rem;
                    font-size: .32rem;
                    overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
                    @include font_color("title_color");
                }
                .bottom{
                    height: .9rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    .num{
                        display: flex;
                        align-items: flex-end;

                        .reduce,.increase{
                            width: .48rem;
                            height: .48rem;
                            background-size: 100% 100%;
                        }
                        .reduce{
                            @include bg_image("pre_order_reduce_bg_img");
                        }
                        .increase{
                            @include bg_image("pre_order_increase_bg_img");
                        }
                        .reduce_d{
                            @include bg_image("pre_order_reduce_bg_img_disabled");
                        }
                        .increase_d{
                            @include bg_image("pre_order_increase_bg_img_disabled");
                        }
                        .txt{
                            width: .58rem;
                            height: .48rem;
                            line-height: .48rem;
                            font-size: .32rem;
                            color: #222222;
                            font-weight: bold;
                            text-align: center;
                            @include font_color("title_color");
                        }
                        .txt2{
                            font-size: .32rem;
                            color: #AEB5CA;
                        }
                    }
                    .price{
                        font-weight: bold;
                        color: #222222;
                        display: flex;
                        align-items: baseline;
                        @include font_color("title_color");
                        display: flex;
                        justify-content: center;
                        .vipTag {
                            width: 1.04rem;
                            height: 0.36rem;
                            line-height: 0.4rem;
                            text-align: center;
                            font-size: 0.22rem;
                            color: #222427;
                            background: #FFEFAE;
                            border-radius: 0.08rem;
                            font-weight: 400;
                            margin-right: 0.2rem;
                        }
                        .sym{
                            font-size: .24rem;
                        }
                        .big{
                            font-size: .4rem;
                        }
                        .small{
                            font-size: .28rem;
                        }
                    }
                }
            }
        }
        .coupon{
            height: .8rem;
            margin: .24rem 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: .28rem;
            color: #222222;
            .left{
                display: flex;
                align-items: center;
                line-height: .4rem;
                @include font_color("title_color");

                .icon{
                    width: .4rem;
                    height: .4rem;
                    margin-right: .12rem;
                }
            }
            .right{
                display: flex;
                align-items: center;
                line-height: .4rem;
                .not{
                    font-size: .28rem;
                    color: #AEB5CA;
                }
                .red{
                    color: #FF4A27;
                    .sym{font-size: .24rem;}
                    .big{font-size: .40rem;}
                    .small{font-size: .28rem;}
                }
                .icon{
                    width: .4rem;
                    height: .4rem;
                    margin-left: .08rem;
                }
            }
        }
    }
    .foot{
        width: 100%;
        /*height: 1.28rem;*/
        height: 1.84rem;
        background: #FFFFFF;
        box-shadow: 0 -0.02rem .06rem 0 rgba(85,85,85,0.04);
        position: fixed;
        z-index: 2;
        bottom: 0;
        left: 0;
        padding: 0 .4rem;
        padding-bottom: .56rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        @include background_color("card_bg");

        .left{
            display: flex;
            flex-direction: column;
            font-size: .36rem;
            color: #222222;

            .price{
                @include font_color("title_color");
                .sym{
                    font-size: .3rem;
                    font-weight: bold;
                }
                .big{
                    font-size: .48rem;
                    font-weight: bold;
                }
                .small{
                    font-size: .32rem;
                    font-weight: bold;
                }
            }
            .num{
                font-size: .2rem;
                color: #AEB5CA;
                margin-top: .06rem;

                .red{
                    font-size: .22rem;
                    color: #FF4A26;
                    font-weight: bold;
                    margin: 0 .04rem;
                }
            }
        }
        .payBtn{
            background: #FF613E;
            border-radius: .47rem;
            width: 2.56rem;
            height: .88rem;
            line-height: .88rem;
            font-size: .4rem;
            color: #FFFFFF;
            text-align: center;
        }
    }
    //排队
    .lineUpModal {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .box {
            width: 5.98rem;
            background: #FFFFFF;
            border-radius: .16rem;
            text-align: center;

            .img {
                width: 5.98rem;
                height: 3.00rem;
                display: block;
            }

            .tit {
                font-size: .40rem;
                color: #222222;
                margin-top: .40rem;
                font-weight: bold;
            }

            .txt {
                font-size: .32rem;
                color: #222222;
                margin-top: .28rem;
            }

            .red {
                color: #E71F19;
            }

            .btn {
                width: 5.18rem;
                height: .88rem;
                background-image: linear-gradient(90deg, #FBE381 0%, #F6C24A 100%);
                border-radius: .44rem;
                font-size: .32rem;
                color: #222222;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: .40rem auto;
            }
        }
    }
    .payType{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.60);
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;

        .box{
            width: 100%;
            background: #FFFFFF;
            border-radius: .24rem .24rem 0 0;
            @include background_color("pre_order_bg");

            .tit{
                height: 1.28rem;
                line-height: 1.28rem;
                text-align: center;
                font-size: .4rem;
                color: #222222;
                font-weight: bold;
                @include font_color("title_color");
            }
            .item{
                height: 1.09rem;
                padding: 0 .4rem 0 .48rem;
                font-size: .32rem;
                color: #222222;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: .01rem solid #ECEDF2;
                @include font_color("title_color");
                @include border_top_color("home_btn_txt_color_disabled");
                [data-theme="light"] &:active{
                    background: rgba(0,0,0,0.15) !important;
                }


                .left{
                    display: flex;
                    align-items: center;
                    img{
                        width: .36rem;
                        height: .36rem;
                        margin-right: .24rem;
                    }
                }
                .right{
                    width: .4rem;
                    height: .4rem;
                    background-size: 100% 100%;
                    @include bg_image("pre_order_arr");
                }
            }
            .cancel{
                height: 1.23rem;
                line-height: 1.09rem;
                text-align: center;
                font-size: .32rem;
                color: #AEB5CA;
                border-top: .14rem solid #ECEDF2;
                @include border_top_color("pre_order_top_border");
                @include font_color("pre_order_cancel_color");

                [data-theme="light"] &:active{
                    background: rgba(0,0,0,0.15) !important;
                }
            }
        }
    }
    .cancelOrderModal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);

        .modalBox {
            width: 6.10rem;
            /*height: 4.33rem;*/
            border-radius: .08rem;
            padding: .30rem .40rem 0;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .tit {
                font-size: .48rem;
                color: #181944;
                height: .67rem;
                font-weight: bold;
            }

            .desc {
                padding: .30rem .10rem .40rem;
                font-size: .36rem;
                color: #222;
                font-weight: bold;
                border-bottom: .01rem solid #E0E0E0;

            }

            .btnRow {
                height: 1.05rem;
                overflow: hidden;

                & > .btn {
                    width: 50%;
                    float: left;
                    font-size: .32rem;
                    text-align: center;
                    padding: .30rem 0;
                }

                .confirm {
                    width: 100%;
                    color: #F6C24A;
                    /*transform: translateX(.20rem);*/
                    background: transparent;
                    line-height: normal;
                }

                .cancel {
                    color: #555;
                    transform: translateX(-.20rem);
                }
            }

            .line {
                position: absolute;
                z-index: 2;
                left: 50%;
                bottom: .22rem;
                width: .01rem;
                height: .60rem;
                background: #E0E0E0;
            }
        }
    }
</style>

<template>
    <div class="BODY">
        <!--返回App弹窗-->
        <div class="cancelOrderModal" v-if="showModal">
            <div class="modalBox">
                <div class="tit">提示</div>
                <div class="desc">{{modalTxt}}</div>
                <div class="btnRow">
                    <div class="btn confirm" @click="confirm2()">确定</div>
                </div>
                <!--<div class="line"></div>-->
            </div>
        </div>

        <div class="head" ref="refHeight">订单确认</div>

        <!--商品列表-->
        <div class="skuList">
            <div class="item" v-for="item in skuList">
                <div class="img">
                    <img class="image" :src="item.skuImage" />
                    <img v-if="item.region" :src="require(`../assets/imgs/${item.region.toLowerCase()}.png`)" alt="" class="region">
                </div>
                <div class="info">
                    <div class="name">{{item.skuName}}</div>
                    <div class="bottom">
                        <div class="num" v-if="origin === '1'">
                            <div v-if="item.number > 1" class="reduce" @click="reduce(item)"></div>
                            <div v-else class="reduce reduce_d"></div>

                            <span class="txt">{{item.number}}</span>

                            <div v-if="item.number < maxNum" class="increase" @click="increase(item)"></div>
                            <div v-else class="increase increase_d"></div>
                        </div>
                        <div class="num" v-else>
                            <span class="txt2">x{{item.skuCount}}</span>
                        </div>
                        <!-- APP专享价 -->
                        <div class="price" v-if="item.promotionRemark === 2">
                            <span class="vipTag">APP专享</span>
                            <span class="sym">￥</span>
                            <span class="big">{{item.big}}</span>
                            <span class="small">{{item.small}}</span>
                        </div>
                        <div class="price" v-else>
                            <span class="sym">￥</span>
                            <span class="big">{{item.big}}</span>
                            <span class="small">{{item.small}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="coupon" @click="selectCoupon">
                <div class="left">
                    <img src="../assets/imgs/coupon_icon.png" alt="" class="icon">
                    <span>优惠券</span>
                </div>
                <div class="right" style="margin-right: -0.1rem">
                    <span class="red" v-if="couponInfo && couponInfo.id">
                        <span class="sym">￥</span>
                        <span class="big">{{discount[0]}}</span>
                        <span class="small">{{discount[1]}}</span>
                    </span>
                    <span class="not red" v-else-if="couponInfo && couponInfo.id === ''">{{couponAvailableLength}}张可用</span>
                    <span class="not" v-else>无可用优惠</span>
                    <img src="../assets/imgs/arrRight.png" alt="" class="icon">
                </div>
            </div>

            <div class="coupon">
                <div class="left">
                    <img src="../assets/imgs/vipIcon.png" alt="" class="icon">
                    <span>APP专享优惠</span>
                </div>
                <div class="right">
                    <span class="red" v-if="appOrderDiscountPrice">
                        <span class="sym">￥</span>
                        <span class="big">{{appOrderDiscountPriceArr[0]}}</span>
                        <span class="small">{{appOrderDiscountPriceArr[1]}}</span>
                    </span>
                    <span class="not" v-else-if="couponInfo && couponInfo.id">不可与优惠券同时使用</span>
                    <span class="not" v-else>无可用优惠</span>
                </div>
            </div>
        </div>

        <!--底部支付按钮-->
        <div class="foot">
            <div class="left">
                <div class="price">
                    <span>合计：</span>
                    <span class="sym">￥</span>
                    <span class="big">{{totalPrice[0]}}</span>
                    <span class="small">{{totalPrice[1]}}</span>
                </div>
                <div class="num">共计<span class="red">{{totalCount}}</span>件商品</div>
            </div>
            <div class="payBtn" @click="prePay">立即支付</div>
        </div>

        <!--选择支付方式-->
        <div class="payType" v-if="paying">
            <div class="box">
                <div class="tit">选择支付方式</div>
                <!--支付宝支付 1-->
                <!-- <div class="item" @click="lockOrder(1)">
                    <div class="left">
                        <img src="../assets/imgs/aliPay.png" alt="">
                        <span>支付宝</span>
                    </div>
                    <div class="right"></div>
                </div> -->
                <!--微信支付 0-->
                <div class="item" @click="lockOrder(0)">
                    <div class="left">
                        <img src="../assets/imgs/weChatPay.png" alt="">
                        <span>微信支付</span>
                    </div>
                    <div class="right"></div>
                </div>
                <div class="cancel" @click="cancel">取消</div>
            </div>
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request"

    export default {
        name: 'PreOrder',
        components: {},
        props: {},
        data() {
            return {
                vin:'',
                origin:null,
                orderCode: '',
                skuList: [],
                couponInfo:null,//优惠券
                discount:[0,''],//已优惠
                totalPrice:[0,''],//最终价格
                totalCount:'--',//商品总数量
                maxNum:5,
                paying:false,//是否在支付中
                skuListStr:'',
                showModal:false,//弹窗
                modalTxt:'该订单已被其他用户占用',//弹窗文案
                app:null,
                scrollTop: 0,
                appOrderDiscountPrice: 0,
                appOrderDiscountPriceArr: [0,''],
                couponAvailableLength: 0,
            }
        },
        computed: {},
        activated() {
            // 等到DOM更新完成之后，然后，执行this.$nextTick，类似于Promise then()
            this.$nextTick(() => {
                this.app.scrollTo(0, this.scrollTop * -1);
            });
        },
        mounted: function () {
            this.paying = false;
            this.app = document.getElementById('app');
            this.app.addEventListener("scroll", this.handleScroll);

            console.log(this.$route, 'this.$route')
            if (this.$route.query.vin) {
                this.vin = this.$route.query.vin;
                this.getVinInfo();
            }
            else{
                // this.vin = sessionStorage.getItem('vin');
            }
            this.orderCode = this.$route.query.orderCode;
            this.origin = this.$route.query.origin || null;

            if (sessionStorage.getItem('couponInfo')) {
                let couponInfo = JSON.parse(sessionStorage.getItem('couponInfo'));
                this.couponInfo = couponInfo;
                console.log(couponInfo, 'couponInfo')
                this.skuListStr = couponInfo.skuListStr || '';
                this.discount = this.$splitPrice(couponInfo.deratePrice);//已优惠
                console.log('couponInfo.resultPrice', couponInfo.resultPrice)
                this.totalPrice = this.$splitPrice(couponInfo.resultPrice || 0);//最终价格
                sessionStorage.removeItem('couponInfo');
            }
            /*if (this.$route.query.id) {
                this.skuListStr = this.$route.query.skuListStr || '';
                this.couponInfo = {id: this.$route.query.id};
                this.discount = this.$splitPrice(this.$route.query.deratePrice);//已优惠
                this.totalPrice = this.$splitPrice(this.$route.query.resultPrice);//最终价格
            }*/
            
            this.getOrderDetail();

            //App调用的方法
            window.orderStatusCallback = (payStatus) => {
                console.log('----------------')
                this.checkPayStatus(payStatus);
            };
        },
        destroyed() {
            // this.$refs.contains.removeEventListener("scroll", this.handleScroll);
            this.utils.setTitle("");
            this.app.removeEventListener('scroll', this.handleScroll, false);
            window.orderStatusCallback = () => {};
        },
        methods: {
            getOrderDetail() {
                request({
                    url: "/web/order/orderDetail",
                    // url: "aquaSku/getSkuList",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                        // orderCode: '1002325962',
                    },
                }).then(res => {
                    console.log('res',res)
                    if (res.code === '20185') {
                        this.modalTxt = res.msg;
                        this.showModal = true;
                        return false;
                    }
                    if (res.code && res.code !== '10000') return false;

                    let skuList = res.skuList.map(v=>{
                        if (v.promotionRemark == 2) {   //  app专享
                            v.big = `${v.appSkuPrice}`.split('.')[0];
                            v.small = '.' + (`${v.appSkuPrice}`.split('.')[1] || 0);
                            v.number = v.skuCount;//手动加一个数量
                            return v
                        }
                        v.big = `${v.skuPrice}`.split('.')[0];
                        v.small = '.' + (`${v.skuPrice}`.split('.')[1] || 0);
                        v.number = v.skuCount;//手动加一个数量
                        return v
                    });
                    this.skuList = skuList;

                    console.log(this.couponInfo, '...this.couponInfo')

                    //只有从首页过来才可以选数量
                    if (this.$route.query.origin == '1' && this.couponInfo?.skuListStr) {
                        this.skuList[0].number = +this.skuListStr.split(',')[1].split(';')[0];
                    }
                    // if(!this.couponInfo?.id && this.couponInfo.id !== ''){
                    if(!this.couponInfo?.id && this.couponInfo?.id !== ''){
                        this.upData();//先计算出一个价格再由优惠券接口计算
                        if (res.orderStatusValue !== 1) {
                            this.getCouponList();
                        }
                    }
                    else if(this.couponInfo?.id === ''){
                        this.couponInfo.skuListStr && (this.skuListStr = this.couponInfo.skuListStr);
                        this.couponInfo = {id: null};
                        this.upData();
                        if (res.orderStatusValue !== 1) {
                            this.getCouponList(true);
                        }
                    }
                    else{
                        //计算总数量
                        let totalNum = 0;
                        this.skuList.forEach(v=>{
                            totalNum = totalNum + v.number;
                        });

                        this.totalCount = totalNum;
                    }
                })
            },
            getCouponList(isNoCoupon) {
                let skuListStr = '';
                let totalCount = 0;
                this.skuList.forEach(v=>{
                    totalCount+=v.number;
                    skuListStr = `${skuListStr}${v.skuCode},${v.number};`
                });

                request({
                    url: "/web/coupon/getUserCouponList",
                    // url: "aquaSku/getSkuList",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                        skuCodeSkuCount:skuListStr,
                    },
                }).then((res) => {
                    //推荐了优惠券
                    let deratePrice = 0;    //  以优惠价格
                    let haveCoupon = res.couponAvailableList.find(v=>{
                        if (v.select) {
                            this.couponInfo = v;
                            this.discount = this.$splitPrice(v.deratePrice);//已优惠
                            deratePrice = v.deratePrice;
                            this.totalPrice = this.$splitPrice(v.resultPrice);//最终价格
                            this.totalCount = totalCount;//商品总数量
                            return true;
                        }
                        this.appOrderDiscountPrice = 0;
                        this.appOrderDiscountPriceArr = [0,''];
                    });
                    this.couponAvailableLength = res.couponAvailableList.length;
                    // 没有选择优惠券
                    if (isNoCoupon && res.appOrderDiscountPrice.appOrderDiscountPrice === 0) {
                        this.couponInfo = {id: ""};
                    }
                    
                    // app专享价
                    if(res.appOrderDiscountPrice && (res.appOrderDiscountPrice.appOrderDiscountPrice >= deratePrice || isNoCoupon)) {
                        if (!haveCoupon) {
                            this.couponInfo = null;
                        } else {
                            this.couponInfo = {id: ""};
                        }
                        this.discount = this.$splitPrice(res.appOrderDiscountPrice.appOrderDiscountPrice);
                        this.totalPrice = this.$splitPrice(res.appOrderDiscountPrice.resultPrice);//最终价格

                        console.log(this.totalPrice, '...this.totalPrice');


                        this.totalCount = totalCount;//商品总数量 
                        let aprice = 0;
                        if (res && res.originalPrice && res.appOrderDiscountPrice) {
                            aprice = res.appOrderDiscountPrice.appOrderDiscountPrice <= res.originalPrice ? res.appOrderDiscountPrice.appOrderDiscountPrice : res.originalPrice;
                        }
                        this.appOrderDiscountPriceArr = this.$splitPrice(aprice);
                        this.appOrderDiscountPrice = aprice;
                        return false;
                    }

                    //没有推荐优惠券
                    if (isNoCoupon) {
                        this.couponInfo = {id: ""};
                        this.discount = this.$splitPrice(0);//已优惠
                        this.totalPrice = this.$splitPrice(res.originalPrice);
                        this.totalCount = totalCount;//商品总数量
                    }
                })
            },
            getVinInfo() {
                request({
                    url: "/web/vehicle/getVehicleByWin",
                    method: "POST",
                    data: {
                        vin: this.vin,//303
                    },
                }).then((res) => {
                    console.log('车辆详情',res)
                    this.vinId = res.vinId;
                    sessionStorage.setItem('vinInfo', JSON.stringify(res));
                })
            },
            prePay(){
                //0元单不选择支付方式直接锁单
                if (this.totalPrice[0] == 0 && this.totalPrice[1] == 0) {
                    this.lockOrder();
                }
                else{
                    this.paying = true;
                }
            },
            selectCoupon(){
                let skuListStr = '';
                this.skuList.forEach(v=>{
                    skuListStr = `${skuListStr}${v.skuCode},${v.number};`
                });

                console.log(this.couponInfo, '...this.couponInfo')
                let noCheck = false;
                if (this.couponInfo && this.couponInfo.id === "") {
                    noCheck = true;
                }

                this.$router.push({
                    path: 'Coupon',
                    query: {
                        orderCode: this.orderCode,
                        skuListStr,
                        origin:this.origin,
                        noCheck
                    }
                })
            },
            upData(){
                let skuList = this.skuList;
                let price = 0;

                // 计算总价
                skuList.map(v=>{
                    price = (this.getTotalPrice(price) + v.number * this.getTotalPrice(v.skuPrice))/100;
                });
                //计算总数量
                let totalNum = 0;
                this.skuList.forEach(v=>{
                    totalNum = totalNum + v.number;
                });
                this.totalCount = totalNum;

                this.totalPrice = this.$splitPrice(price);
            },
            //计算价格
            getTotalPrice(arg1, arg2 = 100) {
                let m = 0;
                let s1 = arg1.toString();
                let s2 = arg2.toString();

                try {
                    m += s1.split(".")[1].length
                }
                catch (e) {}

                try {
                    m += s2.split(".")[1].length
                }
                catch (e) {}

                return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
            },
            cancelOrder() {
                // request({
                //     url: "/web/order/cancelOrder",
                //     method: "POST",
                //     data: {
                //         orderCode: this.orderCode,
                //     },
                //     headers: {
                //         "Authorization": "neolixxcx123",
                //         // "Content-Type": "application/x-www-form-urlencoded",
                //     },
                // }).then((res) => {
                //
                // })
            },
            //减少
            reduce(item) {
                console.log(item)
                if (item.number <= 1) return false;
                this.skuList = this.skuList.filter(v => {
                    if (v.skuCode === item.skuCode && v.skuPrice === item.skuPrice) {
                        --v.number
                    }
                    if (v.number > 0) {
                        return v
                    }
                })
                // this.upData();
                this.getCouponList();
            },
            //增加
            increase(item) {
                //这里skuCount震雷传的是数量不是库存所以要先不判断而是注释掉
                /*if (item.number >= item.skuCount) return false;*/
                console.log(item.number)
                console.log(Math.min(item.skuCount, 5))
                let totalNum = 0
                this.skuList.forEach(v => {
                    totalNum = totalNum + v.number;
                })
                if (totalNum >= this.maxNum) {
                    alert(`一次最多购买${this.maxNum}件商品`)
                    return
                }
                this.skuList.map((v, i) => {
                    if (v.skuCode === item.skuCode && v.skuPrice === item.skuPrice) {
                        ++v.number
                    }
                })
                // this.upData();
                this.getCouponList();
            },
            lockOrder(payType){
                //1支付宝，0微信
                let skuListStr = '';
                this.skuList.forEach(v=>{
                    skuListStr += `${v.skuCode},${v.number},${v.skuPrice},${v.region};`
                });

                let appOrderDiscountPrice = 0;
                if (this.appOrderDiscountPrice > 0) {
                    appOrderDiscountPrice = this.appOrderDiscountPrice
                }

                request({
                    url: "/web/order/lockOrder",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                        couponId: this.couponInfo?.id || null,
                        skuListStr,
                        appOrderDiscountPrice
                    },
                }).then((res) => {
                    //0元单
                    if (res.stockOut) {
                        this.giftOrderStockout();
                    }
                    else{
                        this.pay(payType);
                    }
                })
            },
            giftOrderStockout(){
                request({
                    url: "/web/order/giftOrderStockout",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                    },
                }).then((res) => {
                    this.$router.push({
                        path: 'Result',
                        replace: true,
                        query: {
                            orderCode: this.orderCode,
                        }
                    })
                })
            },
            pay(payType) {
                //1支付宝，0微信
                request({
                    url: "/web/pay/toPayOrder",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                        appCheck: 2,//震雷让写固定值2
                        payType,
                    },
                }).then((res) => {
                    let type = payType === 1 ? 'alipay' : 'wechatpay';
                    let obj = {
                        type,
                        body: payType === 1 ? res : {...res},
                    };
                    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                        window.webkit.messageHandlers.NeolixWebView.postMessage(JSON.stringify(obj)); //调用ios方法
                    }
                    else if (/(Android)/i.test(navigator.userAgent)) {
                        window.NeolixWebView.postMessage(JSON.stringify(obj)); //android 是java和js协商定义的调用对象, NeolixWebView.是方法名,obj是参数

                    }
                })
            },
            cancel(){
                this.paying = false;
            },
            checkPayStatus(){
                this.paying = false;
                request({
                    url: "/web/pay/paySuccess",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                    },
                }).then((res) => {
                    this.$router.push({
                        path: 'Result',
                        replace: true,
                        query: {
                            orderCode: this.orderCode,
                        }
                    })
                }).catch(err=>{
                    if (err.code === '500') {
                        return false;
                    }
                    this.cancelPay();
                })
            },
            cancelPay(){
                request({
                    url: "/web/pay/cancelPay",
                    method: "POST",
                    data: {
                        orderCode: this.orderCode,
                    },
                }).then((res) => {

                })
            },
            confirm2(){
                if (this.$route.query.canBack) {
                    history.back();
                }
                else{
                    location.href = 'neolixjs://webview?url=back';
                }
                this.showModal = false;
            },
            cancel2(){
                this.showModal = false;
            },
            handleScroll() {
                let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
                let oneHeight = this.$refs.refHeight.offsetHeight;
                if (-oneHeight >= scrollTop) {
                    this.utils.setTitle("确认订单");
                    console.log('setTitle 确认订单')
                } else {
                    this.utils.setTitle("");
                    console.log('setTitle 空')
                }
            },
            loadEnd(){
            },
        }
    }
</script>
